<template>
  <div>
    <!-- <h3 class="d-flex justify-center">Export To GeoJSON</h3> -->
    <v-row class="mt-2">
      <v-col>
        <v-text-field
          type="number"
          v-model.number="formData.lat"
          label="Latitude"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          type="number"
          v-model.number="formData.lng"
          label="Longitude"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-btn color="primary" class="text-capitalize" @click="add()">
      {{ buttonLabel }}
    </v-btn>
    <v-btn class="ml-3 text-capitalize" dark color="grey" @click="cancel()"
      >Cancel</v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    buttonLabel: {
      type: String,
      default: 'Add',
    },
  },
  data() {
    return {
      formData: this.value || {
        lng: '',
        lat: '',
      },
    };
  },
  methods: {
    add() {
      if (this.formData.lat && this.formData.lng) {
        this.$emit('add', this.formData);
      }
    },
    cancel() {
      this.formData.lat = '';
      this.formData.lng = '';
    },
    reset() {
      this.formData = {
        lng: '',
        lat: ' ',
      };
    },
  },
};
</script>

<style>
</style>
